require("./bootstrap");

jQuery(document).ready(function($) {
    $("#sidebarToggle").on("click", event => {
        console.log("click");
        $("#sidebar").toggleClass("shown");
        $(".content").toggleClass("scutch");
        $("#top-nav").toggleClass("scutch");
    });

    $("#department").on("change", event => {
        $("#changeDepartmentForm").submit();
    });
});
